<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <div style="margin-top: 20px" ref="searchbox">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="adminPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 300px"
              v-model="adminPage.video_category_id"
              placeholder="请选择视频分类"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in departmentTree"
                :key="index"
                :title="item.name"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 200px"
              v-model="adminPage.state"
              placeholder="请选择状态"
            >
              <a-select-option value="1">有效</a-select-option>
              <a-select-option value="2">无效</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchList" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="openAdminAdd"
              ><a-icon type="plus-circle" /> 添加视频</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <div>
        <a-table
          style="margin-top: 20px"
          @change="adminPageChange"
          :columns="adminColumns"
          :data-source="adminList"
          :pagination="false"
          bordered
          rowKey="id"
          :scroll="{ y: tableHeigt }"
        >
          <template slot="img_url" slot-scope="text">
           <view-file :data="text"></view-file>
          </template>
          <template slot="video_url" slot-scope="text">
            <video
              :src="text"
              controls
              style="width: 90px; height: 60px; border: 1px dashed #d9d9d9"
            ></video>
          </template>
          <template slot="state" slot-scope="text">
            <a-tag v-if="text == '1'" color="green">启用</a-tag>
            <a-tag v-else color="red">禁用</a-tag>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a-button
              type="link"
              size="small"
              icon="edit"
              @click="() => openEditDialog(record['id'])"
              >编辑</a-button
            >
            <!-- <a-button
              type="link"
              size="small"
              icon="delete"
              @click="() => delOpenDialog(record['id'])"
              >删除</a-button
            > -->
            <a-button
              type="link"
              size="small"
              icon="radar-chart"
              @click="() => Relatedtopics(record['id'], record['subject_ids'])"
              >关联题目</a-button
            >
            <!-- <a-button
              type="link"
              size="small"
              icon="minus-circle"
              v-if="record['state'] == '1'"
              @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
              >禁用</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="check-circle"
              v-if="record['state'] == '2'"
              @click="() => enabledState(record['id'], 1, '你确定要启用吗？')"
              >启用</a-button
            > -->
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
            :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="adminPage.pageSize"
            :total="adminPage.totalRow"
            v-model="adminPage.start"
            @change="changePage"
          />
        </div>
      </div>
    </div>

    <a-modal
      :maskClosable="false"
      destroyOnClose
      @ok="formAdminDataSubmit"
      okText="确定"
      cancelText="取消"
      width="600px"
      v-model="dialogAdmin"
      title="视频管理"
    >
      <div class="dialog_box">
        <div>
          <a-form-model
            ref="formData"
            :rules="formAdminRules"
            :model="formAdmin"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-form-model-item label="标题" prop="title">
              <a-input placeholder="请输入标题" v-model="formAdmin.title" />
            </a-form-model-item>
            <a-form-model-item label="副标题" prop="sub_title">
              <a-input
                placeholder="请输入副标题"
                v-model="formAdmin.sub_title"
              />
            </a-form-model-item>
            <a-form-model-item label="视频分类" prop="video_category_id">
              <a-select
                v-model="formAdmin.video_category_id"
                placeholder="请选择视频分类"
              >
                <a-select-option
                  v-for="(item, index) in departmentTree"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="简介" prop="desc">
              <a-input
                placeholder="请输入视频简介"
                type="textarea"
                row="2"
                v-model="formAdmin.desc"
              />
            </a-form-model-item>
            <a-form-model-item label="视频封面" prop="img_url">
              <custom-file-list
                :img_url.sync="formAdmin.img_url"
                :type="'single'"
                @changeCustomFile="changeImgUrl"
              />
            </a-form-model-item>
            <a-form-model-item label="视频" prop="video_url">
              <custom-file-list
                :img_url.sync="formAdmin.video_url"
                :type="'single'"
                @changeCustomFile="changeVideoUrl"
              />
            </a-form-model-item>
            <a-form-model-item label="视频时长" prop="duration">
              <a-input
                placeholder="请输入视频时长"
                v-model="formAdmin.duration"
                disabled
              />
            </a-form-model-item>
            <a-form-model-item label="排序" prop="sort_num">
              <a-input placeholder="" v-model="formAdmin.sort_num" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="describe_content">
              <a-textarea
                placeholder="请输入备注"
                v-model="formAdmin.describe_content"
                style="min-height: 100px"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="imgvisible"
      title="封面图预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>

    <a-modal
      v-model="topicsvisible"
      title="关联题目"
      ok-text="确认"
      width="1100px"
      @ok="addTopicsFrom"
    >
      <div style="margin-bottom: 10px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="topicsPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 200px"
              v-model="topicsPage.subject_category_id"
              placeholder="请选择题目分类"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in subjectCategoryData"
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: 200px"
              v-model="topicsPage.type"
              placeholder="请选择题型"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="1">判断题</a-select-option>
              <a-select-option value="2">单选题</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchsList" icon="search"
              >搜索</a-button
            >
          </a-form-item>
          <!-- <a-form-item>
            <a-button
              type="primary"
              icon="add"
              :disabled="!isAddtopics"
              @click="
                topicsPage.start = 1;
                getTopicsList();
              "
              >查看已关联题目</a-button
            >
            <a-button
              style="margin-left: 20px"
              type="primary"
              icon="add"
              :disabled="isAddtopics"
              @click="
                topicsPage.start = 1;
                addTopicsDialog();
              "
              >关联题目</a-button
            >
          </a-form-item> -->
        </a-form>
      </div>
      <a-alert
        style="margin-bottom: 10px"
        :message="'当前已选题目共：' + `${selectedRowKeys.length}` + '条'"
        type="info"
      />
      <div style="max-height: 500px; overflow-y: auto">
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="topicsColumns"
          :data-source="topicsList"
          @change="topicsPageChange"
          @onSelectInvert="onSelectInvert"
          :pagination="{
            total: topicsPage.totalRow,
            defaultPageSize: topicsPage.limit,
            defaultCurrent: 1,
          }"
          bordered
          rowKey="id"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag v-if="text == '1'" color="green">启用</a-tag>
            <a-tag v-else color="red">禁用</a-tag>
          </template>
          <template slot="type" slot-scope="text, record">
            <a-tag v-if="text == '1'" color="blue">判断题</a-tag>
            <a-tag v-else color="cyan">单选题</a-tag>
          </template>
          <!-- <template slot="operation" slot-scope="text, record">
              <a-button
                type="link"
                size="small"
                icon="delete"
                @click="() => delOpenDialog(record['id'])"
                >删除</a-button
              >
            </template> -->
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
import ATextarea from "ant-design-vue/es/input/TextArea";

export default {
  components: { ATextarea },
  data() {
    return {
      tableHeigt: 0,
      imgvisible: false,
      topicsvisible: false,
      isAddtopics: false,
      selectedTopicsData: "", // 已选中的题目ids
      videoId: "", // 当前视频ID
      viewImgData: "", // 封面图预览
      selectedRowKeys: [], //多选题目数据
      page: {
        start: 1,
        limit: 10,
        key: "",
        totalRow: 0,
      },
      roleList: [],
      departmentTree: [],
      topicsTree: [],
      selectCurrentNodeId: "",
      adminList: [],
      topicsList: [],
      adminPage: {
        start: 1,
        limit: 10,
        pageSize: 10,
        totalRow: "",
        video_category_id: undefined,
        key: "",
        state: undefined,
        totalRow: 0,
      },
      topicsPage: {
        start: 1,
        limit: 10,
        state: "1",
        id: "",
        subject_category_id: "",
        totalRow: 0,
        type: undefined,
        key: "",
      },
      formAdmin: {
        type: 2,
        title: "",
        video_category_id: "",
        subject_ids: "",
        sub_title: 1,
        desc: "",
        img_url: "",
        video_url: "",
        duration: "",
        sort_num: 0,
        content: "",
      },
      formAdminRules: {
        video_category_id: [
          { required: true, message: "请选择视频分类", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        img_url: [
          { required: true, message: "请上传封面图", trigger: "change" },
        ],
        video_url: [
          { required: true, message: "请上传视频", trigger: "change" },
        ],
        sort_num: [
          { required: true, message: "请输入排序", trigger: "change" },
        ],
      },
      dialogAdmin: false,
      adminColumns: [
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "副标题",
          dataIndex: "sub_title",
          key: "sub_title",
        },
        {
          title: "简介",
          dataIndex: "desc",
          key: "desc",
        },
        {
          title: "视频封面",
          dataIndex: "img_url",
          key: "img_url",
          scopedSlots: { customRender: "img_url" },
        },
        {
          title: "视频",
          dataIndex: "video_url",
          key: "video_url",
          scopedSlots: { customRender: "video_url" },
        },
        {
          title: "上传时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "视频时长",
          dataIndex: "duration",
          key: "duration",
        },

        {
          title: "备注",
          dataIndex: "content",
          key: "content",
        },

        {
          title: "操作",
          fixed: "right",
          dataIndex: "operation",
          key: "operation",
          width: 200,
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      topicsColumns: [
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          width: 340,
        },
        {
          title: "题型",
          dataIndex: "type",
          key: "type",
          width: 70,
          scopedSlots: { customRender: "type" },
        },
        {
          title: "正确答案",
          dataIndex: "right_answer",
          key: "right_answer",
          width: 120,
        },

        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 120,
        },
        {
          title: "状态",
          dataIndex: "state",
          key: "state",
          scopedSlots: { customRender: "state" },
          width: 70,
        },
        // {
        //   title: "操作",
        //   fixed: "right",
        //   dataIndex: "operation",
        //   key: "operation",
        //   width: 100,
        //   scopedSlots: { customRender: "operation" },
        // },
      ],
      dialogAdminPwd: false,

      dialogDept: false,
      formDept: {
        pid: "",
        name: "",
        sort: "",
        represent: "",
      },
      formDeptRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序数", trigger: "blur" }],
      },
      topAdminList: [],
      activeVId: "",
      subjectCategoryData: "",
    };
  },
  mounted() {
    this.getTreeList();
    // this.getTopicsTreeList();
    this.getAdminList();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 210;
    });
  },
  methods: {
    // 搜索
    searchList() {
      this.adminPage.start = 1;
      this.getAdminList();
    },
    // 题目搜索
    searchsList() {
      this.topicsPage.start = 1;
      if (this.isAddtopics) {
        this.addTopicsDialog();
      } else {
        this.getTopicsList();
      }
    },
    // TODO:视频分类下拉框
    roleHandleChange(value) {
      this.formAdmin.role_ids = value.toString();
      console.log(this.formAdmin.role_ids);
    },
    async getTreeList() {
      const res = await Api.GetTreeList();
      this.departmentTree = res.treeList;
    },

    /**
     * 选中树节点
     */
    selectTree(key, event) {
      console.log(event.node.dataRef.id);
      this.selectCurrentNodeId = event.node.dataRef.id; // 添加分类的时候
      this.adminPage.video_category_id = event.node.dataRef.id; // 添加视频的时候赋值分类ID
      this.getAdminList();
    },
    /**
     * 选中题目分类树节点
     */
    selectTopicsTree(key, event) {
      if (this.isAddtopics) {
        this.topicsPage.subject_category_id = event.node.dataRef.id; // 筛选题目的时候赋值分类ID
        this.addTopicsDialog();
      }
    },
    /**
     * 重置分类树树form
     */
    resetFormDept() {
      delete this.formDept["id"];
      this.formDept.name = "";
      for (let key in this.formDept) {
        this.formDept[key] = "";
      }
    },
    /**
     * 打开分类树添加的弹框
     */
    openDeptAdd() {
      this.resetFormDept();
      this.dialogDept = true;
      if (this.selectCurrentNodeId == "") {
        this.formDept.pid = 0;
      } else {
        this.formDept.pid = this.selectCurrentNodeId;
      }
    },
    async openDeptEdit() {
      if (this.selectCurrentNodeId == "" || this.selectCurrentNodeId == "0") {
        this.$message.error("请选择要编辑的分类");
      } else {
        this.resetFormDept();
        let res = await Api.DetailDepartment({ id: this.selectCurrentNodeId });
        if (res && res["code"] == "0") {
          this.formDept = Object.assign({}, res.target);
          this.dialogDept = true;
        }
      }
    },
    /**
     * 分类树添加
     */
    addDeptId(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          if (item.children) {
            item.children.push(obj);
          } else {
            item["children"] = [obj];
          }
          break;
        } else if (item.children && item.children.length > 0) {
          this.addDeptId(item.children, id, obj);
        }
      }
    },
    /**
     * 分类树编辑
     */
    editDeptId(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          item.name = obj;
          break;
        } else if (item.children && item.children.length > 0) {
          this.editDeptId(item.children, id, obj);
        }
      }
    },
    /**
     * 添加分类树
     */
    deptDataSubmit() {
      this.$refs["formDept"].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (this.formDept && this.formDept["id"]) {
            // TODO:区分是新增还是编辑
            res = await Api.UpdateDepartment(this.formDept);
          } else {
            res = await Api.SaveDepartment(this.formDept);
          }
          if (res && res["code"] == "0") {
            this.getTreeList();
            this.resetFormDept();
            this.dialogDept = false;
            this.$message.success(res.message);
            this.addDeptId();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 打开分类树删除弹框
     */
    openDeptDelete() {
      if (this.selectCurrentNodeId == "") {
        this.$message.error("不能删除或没有选择节点");
        return;
      }
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.DeleteDepartment({
            id: that.selectCurrentNodeId,
          });
          if (res && res["code"] == "0") {
            that.deleteDeptId(that.departmentTree, that.selectCurrentNodeId);
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    /**
     * 分类树删除
     */
    deleteDeptId(arr, id) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr = arr.splice(i, 1);
          break;
        } else if (item.children && item.children.length > 0) {
          this.deleteDeptId(item.children, id);
        }
      }
    },
    /**
     * 获取视频分类下拉列表
     */
    async getRoleList() {
      const res = await Api.GetRoleList();
      this.roleList = res.list;
    },

    /**
     * 获取视频列表
     */
    async getAdminList() {
      const res = await Api.GetAdminList({ ...this.adminPage });
      this.adminList = res.page.list;
      this.adminPage.totalRow = res.page.totalRow;
    },
    changePage(currentPage) {
      this.adminPage.start = currentPage;
      this.getAdminList();
    },
    /**
     * 切换到新增题目
     */
    async addTopicsDialog() {
      this.isAddtopics = true;
      const res = await Api.GetAllTopicsList(this.topicsPage);
      this.topicsList = res.page.list;
      this.topicsPage.totalRow = res.page.totalRow;

      // this.selectedRowKeys = [];
    },
    // 保存关联题目
    async addTopicsFrom() {
      let arr = [];
      this.resetFormAdmin();
      let res = await Api.AdminDetail({ id: this.activeVId });
      if (res && res["code"] == "0") {
        // res["admin"]["role_id"] = parseInt(res["admin"]["role_id"]);
        this.formAdmin = Object.assign({}, res.target);
      }
      // console.log(this.selectedTopicsData.split(","));
      // if (this.selectedTopicsData != "") {
      //   arr = [
      //     ...new Set([
      //       ...this.selectedTopicsData.split(","),
      //       this.selectedRowKeys,
      //     ]),
      //   ]; //去重已添加的题目id
      // } else {
      //   arr = this.selectedRowKeys;
      // }
      // let params = [...new Set(arr.join(",").split(","))].join(",");

      // if (params.substr(0, 1) == ",") {
      //   params = params.substr(1);
      // }
      // console.log(params);
      console.log(this.selectedRowKeys);
      this.formAdmin.subject_ids = this.selectedRowKeys.toString();
      this.relationSubject();
    },
    // 关联题目
    async relationSubject() {
      let res = await Api.AdminUpdate({ ...this.formAdmin });
      if (res && res["code"] == "0") {
        this.isAddtopics = false;
        this.topicsvisible = false;
        this.$message.success(res.message);
        this.getTopicsList();
        this.getAdminList();
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 获取已关联题目列表
     */
    async getTopicsList() {
      this.isAddtopics = false;
      const res = await Api.GetTopicsList(this.topicsPage);
      this.topicsList = res.page.list;
      this.topicsPage.totalRow = res.page.totalRow;
      this.selectedRowKeys = this.selectedTopicsData.split(",").map(parseFloat);
      console.log(this.selectedRowKeys);
    },
    /**
     * 视频分页操作
     */
    adminPageChange(e) {
      this.adminPage.start = e["current"];
      this.getAdminList();
    },
    /**
     * 题目分页操作
     */
    topicsPageChange(e) {
      console.log(e);
      this.topicsPage.start = e["current"];
      if (this.isAddtopics) {
        this.addTopicsDialog();
      } else {
        this.getTopicsList();
      }
    },
    /**
     * 用户手动选择反选的回调
     */
    onSelectInvert(e) {
      console.log(e);
    },
    /**
     * 重置视频form
     */
    resetFormAdmin() {
      for (let key in this.formAdmin) {
        this.formAdmin[key] = "";
      }
    },
    /**
     * 打开视频添加窗口
     */
    openAdminAdd() {
      this.resetFormAdmin();

      this.dialogAdmin = true;
    },
    /**
     * 获取题目分类下拉列表
     */
    async getsubjectCategory() {
      const res = await Api.GetTopicsTreeList();
      this.subjectCategoryData = res.treeList;
    },
    /**
     * 打开视频编辑窗口
     */
    async openEditDialog(id) {
      this.resetFormAdmin();
      let res = await Api.AdminDetail({ id: id });
      if (res && res["code"] == "0") {
        // res["admin"]["role_id"] = parseInt(res["admin"]["role_id"]);
        this.formAdmin = Object.assign({}, res.target);
        this.dialogAdmin = true;
      }
      // this.$message.success(res.message);
    },
    /**
     * 视频提交
     */
    formAdminDataSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (this.formAdmin && this.formAdmin["id"]) {
            res = await Api.AdminUpdate(this.formAdmin);
          } else {
            res = await Api.AdminSave(this.formAdmin);
          }
          if (res && res["code"] == "0") {
            this.resetFormAdmin();
            this.dialogAdmin = false;
            this.getAdminList();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 删除视频
     */
    delOpenDialog(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.AdminDelete({ id: id });
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.DisabledEnabled(params);
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    // 选中项发生变化时的回调
    onSelectChange(arr) {
      this.selectedRowKeys = arr;
      console.log("selectedRowKeys changed: ", arr);
    },
    // 点击关联题目按钮
    Relatedtopics(id, subject_ids) {
      console.log(id, subject_ids);
      // 获取题目分类subjectCategoryData
      this.getsubjectCategory();
      this.topicsList = [];
      this.topicsPage.start = 1;
      this.topicsvisible = true;
      this.topicsPage.id = id;
      this.activeVId = id;
      // this.selectedTopicsData = subject_ids != "" ? subject_ids : "";
      this.selectedRowKeys =
        subject_ids != "" && subject_ids != null
          ? subject_ids.split(",").map(parseFloat)
          : [];
      this.addTopicsDialog();
      // this.getTopicsList();
    },
    /**
     * 视频封面上传完成的事件
     * @author wwq
     * */
    changeImgUrl(url) {
      this.formAdmin.img_url = url;
    },
    // 秒转换分钟.秒数
    toMinute(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      h = h.length == 1 ? h : h;
      s = s.length == 1 ? +s : s;
      return h + "." + s;
    },
    /**
     * 视频上传完成的事件
     * @author wwq
     * */
    changeVideoUrl(url) {
      let that = this;
      if (url == "") {
        return;
      }
      this.formAdmin.video_url = url;
      var audioElement = new Audio(url);
      var durations;
      audioElement.addEventListener("loadedmetadata", function (_event) {
        durations = audioElement.duration; //时长为秒，小数，182.36
        console.log(Math.round(durations));
        that.formAdmin.duration = that.toMinute(Math.round(durations));
      });
    },
    hideModal() {
      this.imgvisible = false;
      this.topicshideModal = false;
    },
  },
};
</script>

<style>
/*.ant-layout-content .main_content {*/
/*display: flex;*/
/*flex-direction: column;*/
/*height: 100%;*/
/*}*/
.addTopics {
  width: 800px;
  display: flex;
}
</style>

