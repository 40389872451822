
import request from '@/utils/request';

/**
 * 获取分类树结构
 */
export function GetTreeList() {
    return request({
        url: '/admin/videoCategory/getTreeList',
        method: 'GET',
    });
}
/**
 * 获取题目分类树结构
 */
export function GetTopicsTreeList() {
    return request({
        url: '/admin/subjectCategory/getTreeList',
        method: 'GET',
    });
}
/**
 * 创建分类
 */
export function SaveDepartment(data) {
    return request({
        url: '/admin/videoCategory/save',
        method: 'POST',
        data
    });
}

/**
 * 修改分类
 */
export function UpdateDepartment(data) {
    return request({
        url: '/admin/videoCategory/update',
        method: 'POST',
        data
    });
}

/**
 * 删除分类
 */
export function DeleteDepartment(params) {
    return request({
        url: '/admin/videoCategory/delete',
        method: 'GET',
        params
    });
}

/**
 * 分类详情
 */
export function DetailDepartment(params) {
    return request({
        url: '/admin/videoCategory/detail',
        method: 'GET',
        params
    });
}

/**
 * 视频下拉列表
 */
export function GetRoleList(params) {
    return request({
        url: '/admin/video/getBusVideoCategoryJson',
        method: 'GET',
        params
    });
}

/**
 * 视频列表
 */
export function GetAdminList(params) {
    return request({
        url: '/admin/video/page',
        method: 'GET',
        params
    });
}

/**
 * 已关联题目列表
 */
export function GetTopicsList(params) {
    return request({
        url: '/admin/video/getSubjects',
        method: 'GET',
        params
    });
}
/**
 * 全部题目列表
 */
export function GetAllTopicsList(params) {
    return request({
        url: '/admin/subject/page',
        method: 'GET',
        params
    });
}

/**
 * 添加视频
 */
export function AdminSave(data) {
    return request({
        url: '/admin/video/save',
        method: 'POST',
        data
    });
}

/**
 * 编辑视频
 */
export function AdminUpdate(data) {
    return request({
        url: '/admin/video/update',
        method: 'POST',
        data
    });
}

/**
 * 详情 视频
 */
export function AdminDetail(params) {
    return request({
        url: '/admin/video/detail',
        method: 'GET',
        params
    });
}

/**
 * 删除视频
 */
export function AdminDelete(params) {
    return request({
        url: '/admin/video/delete',
        method: 'GET',
        params
    });
}


/**
 * 启用、禁用视频
 */
export function DisabledEnabled(params) {
    return request({
        url: '/admin/video/changeIndex',
        method: 'GET',
        params
    });
}





